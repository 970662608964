/* bike_cal.css */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 20px;
}

form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

form label {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}

form input, form select {
  width: 100%; /* 전체 너비 */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box; /* 여백 문제 해결 */
  background-color: #f9f9f9;
}

form button {
  background-color: #4285f4;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px; /* 버튼 사이 간격 */
}

form button:last-child {
  margin-right: 0;
}

form button:hover {
  background-color: #357ae8;
}

.result-container {
  background-color: #e8f0fe;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.result-container h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

/* 미디어 쿼리 (모바일 대응) */
@media (max-width: 600px) {
  form {
    padding: 15px;
    max-width: 100%; /* 모바일에서는 전체 너비 사용 */
  }

  form h2 {
    font-size: 20px;
  }

  form input, form select {
    font-size: 14px;
  }

  .result-container {
    padding: 15px;
  }

  form button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
